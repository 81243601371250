import axios from 'axios';
import { useState } from 'react';
import { getStore } from '../components/GetStore';

const API_URL = `${process.env.REACT_APP_API_URL}`;

const usePickFormController = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const submitPickForm = async (itemName, quantity) => {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const storeId = getStore(); // Retrieve the current store ID
      const body = {
        item_name: itemName,
        quantity,
        store_id: storeId,
      };

      const res = await axios.post(`${API_URL}/v2/picker_form`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setResponse(res.data);
    } catch (err) {
      setError(
        err.response
          ? err.response.data.detail
          : 'An unexpected error occurred.'
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    submitPickForm,
    loading,
    error,
    response,
  };
};

export default usePickFormController;
