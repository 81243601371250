import React, { useState } from 'react';
import useStatsController from '../controller/StatsController';
import {
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const StatsView = () => {
  const {
    displayedClients,
    searchQuery,
    setSearchQuery,
    fetchClientOrders,
    resetSearch,
    setPageIndex,
    loading,
  } = useStatsController();

  const [dateRanges, setDateRanges] = useState({});
  const [filterActive, setFilterActive] = useState(false);

  const handleDateChange = (clientName, dateType, newValue) => {
    setDateRanges((prevDateRanges) => ({
      ...prevDateRanges,
      [clientName]: {
        ...prevDateRanges[clientName],
        [dateType]: newValue,
      },
    }));
  };

  const handleFilterByDate = (clientName) => {
    setFilterActive(true);
  };

  const handleResetDateRange = (clientName) => {
    setDateRanges((prevDateRanges) => ({
      ...prevDateRanges,
      [clientName]: {
        start: '',
        end: '',
      },
    }));
    setFilterActive(false);
  };

  const filterOrdersByDate = (orders, clientName) => {
    if (!filterActive) return orders;

    const dateRange = dateRanges[clientName];
    if (!dateRange || !dateRange.start || !dateRange.end) return orders;

    const start = dayjs(dateRange.start).startOf('day');
    const end = dayjs(dateRange.end).endOf('day');

    return orders.filter((order) => {
      const deliveredAt = dayjs(order.delivered_at);
      return (
        deliveredAt.isSameOrAfter(start) && deliveredAt.isSameOrBefore(end)
      );
    });
  };

  const calculateAvgDeliveryTime = (orders) => {
    if (orders.length === 0) return 'No valid orders';

    const totalDeliveryTime = orders.reduce((acc, order) => {
      const deliveryTimeMinutes = parseInt(order.delivery_time, 10);
      return acc + deliveryTimeMinutes;
    }, 0);

    const avgDeliveryTime = totalDeliveryTime / orders.length;

    if (avgDeliveryTime >= 60) {
      const hours = Math.floor(avgDeliveryTime / 60);
      const minutes = Math.round(avgDeliveryTime % 60);
      return `${hours} hours ${minutes} minutes`;
    }

    return `${Math.round(avgDeliveryTime)} minutes`;
  };

  const calculateFilteredAvgDeliveryTime = (orders, clientName) => {
    const filteredOrders = filterOrdersByDate(orders, clientName);
    if (filteredOrders.length === 0) return 'No valid orders';

    const totalDeliveryTime = filteredOrders.reduce((acc, order) => {
      const deliveryTimeMinutes = parseInt(order.delivery_time, 10);
      return acc + deliveryTimeMinutes;
    }, 0);

    const avgDeliveryTime = totalDeliveryTime / filteredOrders.length;

    if (avgDeliveryTime >= 60) {
      const hours = Math.floor(avgDeliveryTime / 60);
      const minutes = Math.round(avgDeliveryTime % 60);
      return `${hours} hours ${minutes} minutes`;
    }

    return `${Math.round(avgDeliveryTime)} minutes`;
  };

  const handleSearch = () => {
    setPageIndex(0);
    fetchClientOrders(0);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '50px 20px',
        margin: '0 auto',
        flexGrow: 1,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
          width: {
            md: '100%',
            lg: '50%',
          },
        }}>
        <TextField
          label='Search Clients'
          variant='outlined'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{ flex: 1, marginRight: '10px' }}
        />
        <Button
          variant='contained'
          color='primary'
          onClick={handleSearch}
          sx={{ marginRight: '10px' }}>
          Search
        </Button>
        <Button variant='contained' color='secondary' onClick={resetSearch}>
          Reset
        </Button>
      </Box>
      {loading && (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && displayedClients.length === 0 ? (
        <Typography
          sx={{
            color: '#ff0000',
            textAlign: 'left',
            marginTop: '20px',
            paddingLeft: '5px',
          }}>
          No clients found
        </Typography>
      ) : (
        displayedClients.map((client) => (
          <Accordion
            key={`${client.client_name}-${client.customer}`}
            sx={{ marginBottom: '10px', backgroundColor: '#e0f7fa' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ backgroundColor: '#00796b', color: '#ffffff' }}>
              <Typography>
                {client.client_name} ({client.customer}) - Total Orders:{' '}
                {client.total_orders}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ padding: '20px', backgroundColor: '#fafafa' }}>
              <Typography
                variant='h6'
                sx={{ marginBottom: '15px', color: '#00796b' }}>
                Average Delivery Time for All Orders:{' '}
                {calculateAvgDeliveryTime(
                  client.orders_by_time['1-20min'].orders.concat(
                    client.orders_by_time['21-40min'].orders,
                    client.orders_by_time['41-60min'].orders,
                    client.orders_by_time['60+min'].orders
                  )
                )}
              </Typography>
              {filterActive && (
                <Typography
                  variant='h6'
                  sx={{ marginBottom: '15px', color: '#00796b' }}>
                  Filtered Average Delivery Time:{' '}
                  {calculateFilteredAvgDeliveryTime(
                    client.orders_by_time['1-20min'].orders.concat(
                      client.orders_by_time['21-40min'].orders,
                      client.orders_by_time['41-60min'].orders,
                      client.orders_by_time['60+min'].orders
                    ),
                    client.client_name
                  )}
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}>
                <Typography sx={{ marginRight: '10px' }}>
                  Start Date:
                </Typography>
                <input
                  type='date'
                  value={dateRanges[client.client_name]?.start || ''}
                  onChange={(e) =>
                    handleDateChange(
                      client.client_name,
                      'start',
                      e.target.value
                    )
                  }
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}>
                <Typography sx={{ marginRight: '10px' }}>End Date:</Typography>
                <input
                  type='date'
                  value={dateRanges[client.client_name]?.end || ''}
                  onChange={(e) =>
                    handleDateChange(client.client_name, 'end', e.target.value)
                  }
                />
              </Box>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => handleFilterByDate(client.client_name)}
                sx={{ marginBottom: '20px', marginRight: '10px' }}>
                Apply Date Filter
              </Button>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => handleResetDateRange(client.client_name)}
                sx={{ marginBottom: '20px' }}>
                Reset Date Range
              </Button>
              {['1-20min', '21-40min', '41-60min', '60+min'].map(
                (timeSection) => (
                  <Accordion
                    key={timeSection}
                    defaultExpanded
                    sx={{ marginBottom: '10px' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ backgroundColor: '#004FB6FF', color: '#ffffff' }}>
                      <Typography variant='subtitle1'>
                        {timeSection} ---{' '}
                        <span style={{ color: '#FFFFFFFF', fontWeight: '500' }}>
                          Total Orders:{' '}
                          {client.orders_by_time[timeSection].count}
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ padding: '20px', backgroundColor: '#fafafa' }}>
                      <Grid container spacing={2}>
                        {filterOrdersByDate(
                          client.orders_by_time[timeSection].orders,
                          client.client_name
                        ).map((order, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                              sx={{
                                border: '1px solid #00796b',
                                borderRadius: '8px',
                                padding: '10px',
                                backgroundColor: '#ffffff',
                              }}>
                              <Typography variant='body2'>
                                <strong>Order #{order.order_number}</strong>
                              </Typography>
                              <Typography variant='body2'>
                                Delivered at: {order.delivered_at}
                              </Typography>
                              <Typography variant='body2'>
                                Driver: {order.driver_name}
                              </Typography>
                              <Typography
                                variant='body2'
                                sx={{ color: '#ff5722' }}>
                                Delivery Time: {order.delivery_time}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Box>
  );
};

export default StatsView;
