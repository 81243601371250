import axios from 'axios';
import { getStore } from '../components/GetStore';
const API_URL = `${process.env.REACT_APP_API_URL}`;
const X_DISPATCH_KEY =
  'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj';
// Define the headers to be used in every request
const headers = {
  'Content-Type': 'application/json',
  'X-Dispatch-key': X_DISPATCH_KEY,
};

export const getOrderCountsPerDay = async (searchQuery) => {
  try {
    // Fetch storeId using getStore
    const storeId = getStore();
    const body = {
      store: storeId,
      search: searchQuery,
    };
    const response = await axios.post(
      `${API_URL}/driver/get_orders_per_day`,
      body,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching order counts:', error);
    throw error;
  }
};
