import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import usePickFormController from '../controller/PickFormController';

const PickForm = () => {
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState('');
  const { submitPickForm, loading, error, response } = usePickFormController();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (itemName && quantity) {
      await submitPickForm(itemName, parseInt(quantity));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: 400,
        mx: 'auto',
        mt: 5,
        bgcolor: '#f5f5f5',
        marginTop: '100px',
      }}>
      <Typography
        variant='h5'
        component='h2'
        gutterBottom
        sx={{
          color: 'var(--h1)',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}>
        Démonstration de produits
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TextField
          label="Nom de l'article ex: SAT B03361"
          variant='outlined'
          fullWidth
          margin='normal'
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
        />
        <TextField
          label='Quantitée'
          type='number'
          variant='outlined'
          fullWidth
          margin='normal'
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
        {loading ? (
          <Box display='flex' justifyContent='center' my={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            sx={{ mt: 2 }}>
            Envoyer
          </Button>
        )}
        {error && (
          <Alert severity='error' sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {response && (
          <Alert severity='success' sx={{ mt: 2 }}>
            Article ajouté avec succès!
          </Alert>
        )}
      </form>
    </Box>
  );
};

export default PickForm;
